// extracted by mini-css-extract-plugin
export var bold = "s-module--bold--3193c";
export var bringNextSection = "s-module--bringNextSection--9034d";
export var downloadBtn = "s-module--downloadBtn--d16d3";
export var flex = "s-module--flex--66af0";
export var logo = "s-module--logo--f1d69";
export var logoContainer = "s-module--logoContainer--ad43f";
export var logoEmpty = "s-module--logoEmpty--ac1e8";
export var logoRow = "s-module--logoRow--3ddcc";
export var marginBottomL = "s-module--marginBottomL--41413";
export var marginBottomM = "s-module--marginBottomM--8c03d";
export var marginBottomS = "s-module--marginBottomS--fbb6c";
export var marginBottomXL = "s-module--marginBottomXL--4878b";
export var marginLeftL = "s-module--marginLeftL--55769";
export var marginLeftM = "s-module--marginLeftM--1cb46";
export var marginLeftS = "s-module--marginLeftS--38923";
export var marginLeftXL = "s-module--marginLeftXL--41c48";
export var marginLeftXS = "s-module--marginLeftXS--0c3e0";
export var marginRightL = "s-module--marginRightL--4537d";
export var marginRightM = "s-module--marginRightM--7e148";
export var marginRightS = "s-module--marginRightS--61942";
export var marginRightXL = "s-module--marginRightXL--a299b";
export var marginRightXS = "s-module--marginRightXS--7cdb2";
export var marginTopL = "s-module--marginTopL--40fbe";
export var marginTopM = "s-module--marginTopM--37f38";
export var marginTopS = "s-module--marginTopS--bd482";
export var marginTopXL = "s-module--marginTopXL--6c146";
export var overlay = "s-module--overlay--fbcfa";
export var stepImg = "s-module--stepImg--e8e28";
export var stepLink = "s-module--stepLink--a69c8";
export var stepParagraph = "s-module--stepParagraph--c369f";
export var stepSubSubtitle = "s-module--stepSubSubtitle--12b5e";
export var stepSubSubtitleBlue = "s-module--stepSubSubtitleBlue--a26f9";
export var stepSubtitle = "s-module--stepSubtitle--d14e2";
export var stepSubtitleBlue = "s-module--stepSubtitleBlue--23684";
export var textRevealAnimation = "s-module--textRevealAnimation--3b81b";
export var white = "s-module--white--5a1f1";