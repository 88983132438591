import React, { Fragment } from 'react'
import cx from 'classnames'
import * as s from './s.module.css'
import { withdrawalNavigationData } from '../navigationData';
import { GatsbyImage } from "gatsby-plugin-image"

import config from '../../../config';

export default [{
    title: 'Withdrawal',
    imgList: [
        { name: 'withdrawal1', overlay: true },
    ],
    customComponent: ({ fluidImgList }) => <Fragment>
        <p className={cx(s.stepSubtitle)}>
            <span className={s.stepSubtitleBlue}>{`${withdrawalNavigationData[0].title.split('.')[0]}.`}</span>
            {withdrawalNavigationData[0].title.split('.')[1]}
        </p>
        <p className={cx(s.stepParagraph, s.marginTopS, s.marginBottomM)}>
            Withdrawing funds from your account is quite easy, once you've passed <a className={s.stepLink} href={config.externalLinks.kyc} target='_' >KYC (Know Your Customer)</a>.
            All players are required to pass our KYC process prior to withdrawing any funds from your player account.  This feature is a security measure to help keep our site safe for all players and is a requirement of
            the <a href={config.externalLinks.mga} target='_' className={s.stepLink} >MGA</a>.
        </p>
        <p className={cx(s.stepParagraph, s.marginTopS)}>
            If you haven't begun the KYC process to date, simply click on the cashier button, then the withdraw tab and click "Start ID verification".
        </p>
        <p className={cx(s.stepParagraph, s.bold, s.marginBottomM)}>
            Note:  Politically Exposed Person means one who has been entrusted with a prominent public function. A PEP generally presents a higher risk for potential involvement
            in bribery and corruption by virtue of their position and the influence that they may hold.  Politically Exposed Persons are not permitted to play on Virtue Poker.
            Please ONLY click the checkmark if you fall into this category of person.
        </p>
        <GatsbyImage className={cx(s.stepImg, { [s.overlay]: fluidImgList[0].overlay })} image={fluidImgList[0].fluidImg} alt='image'/>
    </Fragment>,
    navigationreferenceid: `#${withdrawalNavigationData[0].id}`,
},
{
    imgList: [
        { name: 'withdrawal2', overlay: true },
    ],
    customComponent: ({ fluidImgList }) => <Fragment>
        <p className={cx(s.stepSubtitle, s.marginTopL)}>
            <span className={s.stepSubtitleBlue}>{`${withdrawalNavigationData[1].title.split('.')[0]}.`}</span>
            {withdrawalNavigationData[1].title.split('.')[1]}
        </p>
        <p className={cx(s.stepParagraph, s.marginTopS, s.marginBottomM)}>
            Once you've passed KYC you will be permitted to withdraw funds from your account.  Simply click the Withdraw tab, enter the amount you wish to withdraw, then click the Withdraw ETH button. 
        </p>
        <GatsbyImage className={cx(s.stepImg, { [s.overlay]: fluidImgList[0].overlay })} image={fluidImgList[0].fluidImg} alt="image"/>
        <p className={cx(s.stepParagraph, s.marginTopM, s.marginBottomS)}>
            Funds will then be transferred from your Virtue Poker account to your external wallet (the ETH address you used when signing up).
        </p>
        <p className={cx(s.stepParagraph, s.bold, s.marginBottomM)}>
            <a href={config.privacyPolicyLink} className={s.stepLink} target='_' >Privacy Policy</a> / <a href={config.termsAndConditionsLink} className={s.stepLink} target='_' >Terms of Use</a>
        </p>
    </Fragment>,
    navigationreferenceid: `#${withdrawalNavigationData[1].id}`,
}];