import React from "react"
import Layout from "../../layout/default"
import Seo from "../../components/seo"
import Withdrawl from '../../components/GettingStarted/withdrawlPage';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Withdrawl" />
    <Withdrawl location={location}/>
  </Layout>
)

export default IndexPage
